<template>
  <a-modal
    title="导入"
    :width="880"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="() => { $emit('ok', fileList) }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="仓库">
              <a-select
                show-search
                allow-clear
                :not-found-content="warehouse_fetching ? undefined : null"
                :default-active-first-option="false"
                :filter-option="false"
                v-decorator="[
                  'warehouse_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="仓库名称"
                @search="this.warehouseSearch"
                @change="handleWarehouseChange"
              >
                <a-spin v-if="warehouse_fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in dataSource_wms_warehouse" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="供应商" >
              <a-select
                showSearch
                allow-clear
                v-decorator="['corporation_id', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                placeholder="请输入供应商名称"
                style="width: 100%"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="this.corporationSearch"
                @change="handleCorporationChange"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in corporationList" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="Excel">
              <a-button type="primary" @click="handleDownLoad">
                <a-icon type="download" />
                下载模板
              </a-button>
              <a-upload
                name="file"
                :multiple="false"
                accept=".xlsx"
                :file-list="fileList"
                :show-upload-list="true"
                :remove="handleRemove"
                :before-upload="beforeUpload">
                <a-button type="primary" style="margin-left: 10px">
                  <a-icon type="upload" />
                  导入
                </a-button>
              </a-upload>
              <span style="color: red">提醒：导入时，所有单元格格式设置成’文本‘</span>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { warehouse_list } from '@/api/c_wms_warehouse'
import { corporation_list } from '@/api/corporation'
import { cargo_space_list } from '@/api/c_wms_cargo_space'
import { download_goods_purchase_template_excel } from '@/api/c_wms_goods_purchase'
import debounce from 'lodash/debounce'

// 表单字段
const fields = ['id', 'name', 'code', 'wms_commodity_purchase_id']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.warehouseSearch = debounce(this.handleWarehouseSearch, 800)
    this.corporationSearch = debounce(this.handleCorporationSearch, 800)
    return {
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 6 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 14 }
        }
      },
      warehouse_fetching: false,
      fetching: false,
      corporationList: [],
      form: this.$form.createForm(this),
      dataSource_wms_warehouse: [],
      fileList: []
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
    this.handleWarehouseSearch(undefined)
    this.handleCorporationSearch(undefined)
  },
  methods: {
    handleCorporationSearch (value) {
      this.corporationList = []
      this.fetching = true
      corporation_list({ name: value }).then(({ data }) => {
        const result = data.entries || []
        this.corporationList = result
      }).finally(() => {
        this.fetching = false
      })
    },
    handleCorporationChange (value) {
      if (value === undefined) {
        this.handleCorporationSearch(undefined)
      }
    },
    handleWarehouseSearch (value) {
      this.warehouse_fetching = true
      this.dataSource_wms_warehouse = []
      warehouse_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_wms_warehouse = result
      }).finally(() => {
        this.warehouse_fetching = false
      })
    },
    handleWarehouseChange (value) {
      const objneo = this.dataSource_wms_warehouse.find((item) => {
        return item.id === value
      })
      console.log(objneo, 'objneo', this.dataSource_wms_warehouse)
      const obj = Object.assign({}, objneo)
      console.log(obj)
      this.form.setFieldsValue({
        corporation_id: undefined
      })
      if (value === undefined) {
        this.handleWarehouseSearch(undefined)
      }
      // this.handleWmsCargoSpaceSearch(value)
    },
    handleWmsCargoSpaceSearch (value) {
      cargo_space_list({ warehouse_id: value }).then((res) => {
        const result = res.data.entries || []
        if (result) {
          result.forEach(item => {
            if (item.corporation_id !== -1) {
              const obj_corporation = this.corporationList.find((cargo_space) => {
                return cargo_space.corporation_id === item.corporation_id
              })
              if (obj_corporation === undefined) {
                this.corporationList.push(item)
              }
            }
          })
        }
      })
    },
    handleRemove (file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = []
    },
    beforeUpload (file) {
      this.fileList = []
      this.fileList.push(file)
      return false
    },
    handleDownLoad () {
      download_goods_purchase_template_excel().then((res) => {
        const link = document.createElement('a')
        const blob = new Blob([res], { type: 'application/octet-stream' })
        console.log(blob)
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', `商品采购.xls`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }
  }
}
</script>
