<template>
  <a-modal
    title="新建"
    :width="880"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="() => { $emit('ok', other_data) }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="仓库">
              <a-select
                :not-found-content="warehouse_fetching ? undefined : null"
                :default-active-first-option="false"
                :filter-option="false"
                show-search
                allow-clear
                v-decorator="[
                  'warehouse_id',
                  {
                    rules: [
                      { required: true, message: '必填项，请填写信息' },
                    ],
                  },
                ]"
                placeholder="请输入仓库名称"
                @search="this.warehouseSearch"
                @change="handleWmsWarehouseChange"
              >
                <a-spin v-if="warehouse_fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in dataSource_wms_warehouse" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="供应商" >
              <a-select
                showSearch
                allow-clear
                v-decorator="['corporation_id', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                placeholder="请输入供应商名称"
                style="width: 100%"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="this.corporationSearch"
                @change="handleCorporationChange"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in corporationList" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="批次">
              <a-input allow-clear placeholder="批次" v-decorator="['batch', {initialValue: '', rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="货品">
              <a-select
                showSearch
                v-decorator="['barcode', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                placeholder="请输入货品名称"
                style="width: 100%"
                :filter-option="false"
                :not-found-content="goods_fetching ? undefined : null"
                allow-clear
                @search="this.goodsSearch"
                @change="handleGoodsChange"
              >
                <a-spin v-if="goods_fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in goodsList" :key="d.barcode" :value="d.barcode">
                  {{ d.name }}（{{ d.barcode }}）
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="销售模式" >
              <a-select
                showSearch
                allow-clear
                v-decorator="['sell_type', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                placeholder="请选择销售模式"
                style="width: 100%"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.goods_sell_type">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="采购数量">
              <a-input-number style="width: 150px" :min="0" v-decorator="['count', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="采购价格">
              <a-input-number style="width: 150px" :precision="2" :min="0" v-decorator="['price', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="购买税率">
              <a-input-number style="width: 150px" :precision="4" :min="0" v-decorator="['buy_tax_rate', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="销售税率">
              <a-input-number style="width: 150px" :precision="4" :min="0" v-decorator="['sell_tax_rate', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="截止日期方式">
              <a-radio-group v-model="other_data.expiration_date_method">
                <a-radio :value="1">
                  截止日期
                </a-radio>
                <a-radio :value="2">
                  到期剩余
                </a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="结束日期" v-if="other_data.expiration_date_method === 1">
              <a-date-picker
                v-decorator="['expiration_date', {rules: [{required: false, message: '必填项，请填写信息'}]}]"
                :disabled-date="disabledEndDate"
                show-time
                valueFormat="YYYY-MM-DD"
                format="YYYY-MM-DD"
                placeholder="结束日期"
                :open="endOpen"
                @openChange="handleEndOpenChange"
              />
            </a-form-item>
            <a-form-item label="开始日期" v-if="other_data.expiration_date_method === 2">
              <a-date-picker
                v-decorator="['start_date', {rules: [{required: false, message: '必填项，请填写信息'}]}]"
                :disabled-date="disabledStartDate"
                show-time
                valueFormat="YYYY-MM-DD"
                format="YYYY-MM-DD"
                placeholder="开始日期"
                @openChange="handleStartOpenChange"
              />
            </a-form-item>
            <a-form-item label="到期剩余" v-if="other_data.expiration_date_method === 2">
              <a-input type="number" v-model="other_data.time_span">
                <a-select
                  slot="addonAfter"
                  v-model="other_data.time_span_type"
                  style="width: 60px">
                  <a-select-option value="1">
                    日
                  </a-select-option>
                  <a-select-option value="2">
                    月
                  </a-select-option>
                  <a-select-option value="3">
                    年
                  </a-select-option>
                </a-select>
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { warehouse_list } from '@/api/c_wms_warehouse'
import { cargo_space_list } from '@/api/c_wms_cargo_space'
import moment from 'moment/moment'
import { goods_list } from '@/api/c_wms_goods'
import { corporation_list } from '@/api/corporation'
import debounce from 'lodash/debounce'

// 表单字段
const fields = ['id', 'name', 'code', 'wms_commodity_purchase_id']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.warehouseSearch = debounce(this.handleWarehouseSearch, 800)
    this.corporationSearch = debounce(this.handleCorporationSearch, 800)
    this.goodsSearch = debounce(this.handleGoodsSearch, 800)
    return {
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 6 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 14 }
        }
      },
      warehouse_fetching: false,
      fetching: false,
      goods_fetching: false,
      endOpen: false,
      other_data: {
        expiration_date_method: 0,
        time_span: 1,
        time_span_type: '1',
        selectGoods: this.selectGoods
      },
      corporationList: [],
      selected_warehouse: undefined,
      warehouse_id: 0,
      corporation_id: 0,
      form: this.$form.createForm(this),
      dataSource_wms_warehouse: [],
      dataSource_wms_cargo_space: [],
      goodsList: [],
      goods_sell_type: this.$Dictionaries.goods_sell_type,
      selectGoods: undefined
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
    this.handleWarehouseSearch(undefined)
    this.handleCorporationSearch(undefined)
    this.handleGoodsSearch(undefined)
  },
  methods: {
    handleCorporationSearch (value) {
      this.corporationList = []
      this.fetching = true
      corporation_list({ name: value }).then(({ data }) => {
        const result = data.entries || []
        this.corporationList = result
      }).finally(() => {
        this.fetching = false
      })
    },
    handleCorporationChange (value) {
      this.corporation_id = value
      if (value === undefined) {
        this.handleCorporationSearch(undefined)
      }
      this.handleGenerateBatch()
    },
    handleGoodsChange (value) {
      this.selectGoods = undefined
      if (value === undefined) {
        this.handleGoodsSearch(undefined)
      } else {
        this.selectGoods = this.goodsList.find(item => value === item.barcode)
        if (this.selectGoods.open_self_life) {
          this.other_data.time_span = this.selectGoods.shelf_life
          this.other_data.time_span_type = '' + this.selectGoods.shelf_life_unit
        } else {
          this.other_data.time_span = 1
          this.other_data.time_span_type = '1'
        }
        this.other_data.selectGoods = this.selectGoods
      }
    },
    handleGenerateBatch () {
      const today = moment()
      const year = today.year()
      const month = today.month() + 1 // 注意月份从0开始，所以要加1
      const date = today.date() > 9 ? today.date() : '0' + today.date()
      if (this.warehouse_id !== 0 && this.warehouse_id !== undefined && this.corporation_id !== 0 && this.corporation_id !== undefined) {
        this.form.setFieldsValue({
          batch: '' + this.warehouse_id + this.corporation_id + year + month + date
        })
      } else {
        this.form.setFieldsValue({
          batch: undefined
        })
      }
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleWarehouseSearch (value) {
      this.dataSource_wms_warehouse = []
        this.warehouse_fetching = true
      warehouse_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_wms_warehouse = result
      }).finally(() => {
        this.warehouse_fetching = false
      })
    },
    handleWmsWarehouseChange (value) {
      const objneo = this.dataSource_wms_warehouse.find((item) => {
        return item.id === value
      })
      console.log(objneo, 'objneo', this.dataSource_wms_warehouse)
      const obj = Object.assign({}, objneo)
      console.log(obj)
      this.selected_warehouse = obj
      this.warehouse_id = value
      this.dataSource_wms_cargo_space = []
      if (value === undefined) {
        this.handleWarehouseSearch(undefined)
      }
      this.handleGenerateBatch()
    },
    handleWmsCargoSpaceSearch (value) {
      cargo_space_list({ warehouse_id: value }).then((res) => {
        const result = res.data.entries || []
        if (result) {
          result.forEach(item => {
            if (item.corporation_id !== -1) {
              const obj_corporation = this.dataSource_wms_cargo_space.find((cargo_space) => {
                return cargo_space.corporation_id === item.corporation_id
              })
              if (obj_corporation === undefined) {
                this.dataSource_wms_cargo_space.push(item)
              }
            }
          })
        }
      })
    },
    disabledStartDate (startValue) {
      const endValue = this.form.getFieldValue('expiration_date')
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate (endValue) {
      const startValue = this.form.getFieldValue('start_date')
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    handleStartOpenChange (open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange (open) {
      this.endOpen = open
    },
    handleGoodsSearch (value) {
      this.goodsList = []
      this.goods_fetching = true
      const requestData = { name: value, goods_type: undefined, is_union: 'false' }
      if (this.selected_warehouse) {
        requestData.goods_type = this.selected_warehouse.goods_type
      }
      console.log('商品搜索', requestData)
      goods_list(requestData).then(({ data }) => {
        const result = data.entries || []
        this.goodsList = result
      }).finally(() => {
        this.goods_fetching = false
      })
    }
  }
}
</script>
