var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"入库","width":680,"visible":_vm.visible,"confirmLoading":_vm.loading},on:{"ok":() => { _vm.$emit('ok') },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"库位"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'cargo_space_id',
                {
                  rules: [
                    { required: true, message: '' },
                  ],
                },
              ]),expression:"[\n                'cargo_space_id',\n                {\n                  rules: [\n                    { required: true, message: '' },\n                  ],\n                },\n              ]"}],attrs:{"option-label-prop":"tt","show-arrow":true,"filter-option":false,"not-found-content":null,"show-search":"","placeholder":"库位名称","default-active-first-option":false},on:{"change":_vm.handleWmsCargoSpaceChange}},_vm._l((_vm.dataSource_wms_cargo_space),function(d){return _c('a-select-option',{key:d.id,attrs:{"tt":d.name}},[_vm._v(" "+_vm._s(d.name)+" ")])}),1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }